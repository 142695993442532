import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {useContext, useState} from "react";
import LanguageIcon from "@mui/icons-material/Language";
import {AppContext} from "../../context/AppContext";
import {Check} from "@mui/icons-material";

const LanguageMenu = props => {

    const appContext = useContext(AppContext)

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
            >
                <LanguageIcon/>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={() => appContext.setLanguage('nl')}>
                    <ListItemIcon>
                        {appContext.language === 'nl' && <Check fontSize="small"/>}
                    </ListItemIcon>
                    <ListItemText>Nederlands</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => appContext.setLanguage('en')}>
                    <ListItemIcon>
                        {appContext.language === 'en' && <Check fontSize="small"/>}
                    </ListItemIcon>
                    <ListItemText>English</ListItemText>
                </MenuItem>

            </Menu>
        </>
    )
}

export default LanguageMenu