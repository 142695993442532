import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import {Box} from "@mui/material";

const Loading = props => {
    if (props.loading) {
        return <LinearProgress color="laurenscantorij"/>
    }
    return <Box sx={{height: 4}}/>
}

export default Loading