import React, {createContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_ONE_CARTITEM_MUTATION, UPSERT_ONE_CARTITEM_MUTATION} from "../utilities/mutation";
import {errorHandler} from "../utilities/funcs";
import {useSnackbar} from "notistack";
import {CARTITEMS_QUERY} from "../utilities/query";


export const AppContext = createContext({
    language: 'nl',
    setLanguage: () => {
    },
    cartitems: [],
    types: {
        cd: false,
        ticket: false,
        donation: false,
        shipping: false
    },
    amounts: {},
    refreshCart: () => {
    },
    upsertCartitem: () => {
    },
    deleteCartitem: () => {
    },
    getAmount: () => {
    },
    total: 0,
    tax: 0,
    findComprop: () => {
    },
    i18nText: ()=>{
    }
});


export const AppProvider = props => {

    const {enqueueSnackbar} = useSnackbar();
    const [language, setLanguage] = useState('nl')
    const [loading, setLoading] = useState(true)

    const [cartitems, setCartitems] = useState([])
    const [amounts, setAmounts] = useState({})
    const [tax, setTax] = useState([])
    const [total, setTotal] = useState(0)
    const [types, setTypes] = useState({
        cd: false,
        ticket: false,
        donation: false,
        shipping: false
    })
    useEffect(() => {
        const nAmounts = {}
        const nTypes = {
            cd: false,
            ticket: false,
            donation: false,
            shipping: false
        }
        let nTotal = 0
        let nTax = []

        for (const cartitem of cartitems) {
            nAmounts[cartitem.subproduct.id] = cartitem.amount
            nTypes[cartitem.subproduct.product.type] = true
            nTotal += (cartitem.amount * cartitem.subproduct.price)
            const addTax = nTax.find(t => t.level === cartitem.subproduct.tax) ?? {
                level: cartitem.subproduct.tax,
                amount: 0
            }
            addTax.amount += ((cartitem.amount * cartitem.subproduct.price) * (cartitem.subproduct.tax / (100 + cartitem.subproduct.tax)))
            nTax = [...nTax.filter(t => t.level !== cartitem.subproduct.tax), addTax]
        }
        if (nTypes.cd) {
            // btw op verzendkosten
            // todo is btw op verzendkosten 9%??
            const addTax = nTax.find(t => t.level === 9) ?? {
                level: 9,
                amount: 0
            }
            addTax.amount += (3.95 * (9 / (100 + 9)))
            nTax = [...nTax.filter(t => t.level !== 9), addTax]
            nTotal += 3.95

        }
        setAmounts(nAmounts)
        setTypes(nTypes)
        setTotal(nTotal)
        setTax(nTax)
        setLoading(false)
    }, [cartitems])


    const refreshCart = () => {
        setLoading(true)
        rerunQuery()
    }


    const upsertCartitem = (id, delta) => {
        setLoading(true)
        upsertCartitemMutation({variables: {where: {id}, delta}})
    }

    const deleteCartitem = (id) => {
        setLoading(true)
        deleteCartitemMutation({variables: {where: {id}}})
    }

    const {loading: queryLoading, refetch: rerunQuery} = useQuery(CARTITEMS_QUERY, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            setLoading(false)
        },
        onCompleted: data => {
            setCartitems(data.cartitems)
        }
    });


    const [upsertCartitemMutation, {
        loading: testCartMutationLoading,
    }] = useMutation(UPSERT_ONE_CARTITEM_MUTATION, {
        onCompleted: (data) => {
            if (data.upsertOneCartitem.amount > 0) {
                enqueueSnackbar(`Item toegevoegd aan de winkelwagen`, {variant: "success"})
            } else {
                enqueueSnackbar(`Item verwijderd`, {variant: "success"})
            }

        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            setLoading(false)
        },
        refetchQueries: ['Cartitems']
    })

    const [deleteCartitemMutation, {
        loading: deleteCartitemMutationLoading,
    }] = useMutation(DELETE_ONE_CARTITEM_MUTATION, {
        onCompleted: (data) => {
            enqueueSnackbar(`Item verwijderd`, {variant: "success"})
            setCartitems(cartitems.filter(c => c.id !== data.deleteOneCartitem.id))
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            setLoading(false)
        }
    })

    const findComprop = (comprops, title, def = "") => {
        const comprop = comprops.find(c => c.title === title)



        if (comprop) {
            if (comprop.type === 'int') return comprop.valueInt
            if (comprop.type === 'string') return comprop.valueString
            return comprop.valueLongtext[language]
        }
        return def
    }

    const i18nText = (item) => {
        return item[language] ?? ''
    }


    return (
        <AppContext.Provider value={{
            language: language,
            setLanguage: setLanguage,
            cartitems: cartitems,
            types: types,
            refreshCart: refreshCart,
            upsertCartitem: upsertCartitem,
            deleteCartitem: deleteCartitem,
            amounts: amounts,
            loading: loading,//testCartMutationLoading || queryLoading || deleteCartitemMutationLoading,
            total: total,
            tax: tax,
            findComprop: findComprop,
            i18nText: i18nText,
        }}>
            {props.children}
        </AppContext.Provider>
    )

}
