import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {createTheme, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {SnackbarProvider} from "notistack";
import {AppProvider} from "./context/AppContext";
import Root from "./Root";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
// import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import {BASE_URL_SERVER} from "./utilities/constants";
import {createUploadLink} from "apollo-upload-client";

const client = new ApolloClient({
    credentials: 'include',
    link: createUploadLink({
        // uri: 'https://graphql.quarpa.nl/graphql',//'http://localhost:4000/graphql', //process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://graphql.quarpa.nl/graphql',
        uri: BASE_URL_SERVER + '/graphql', //process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://graphql.quarpa.nl/graphql',
        credentials: 'include',
        headers:
            {headers: {'Apollo-Require-Preflight': 'true'}}
    }),
    cache: new InMemoryCache({
        //addTypename: false,
        // typePolicies: {
        //     Workplan: {
        //         keyFields: ["date", "daypart", "user", ["id"]]
        //     }
        // }
    }),
    connectToDevTools: true
})

const LinkBehavior = React.forwardRef((props, ref) => {
    const {href, ...other} = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other}/>;
});

LinkBehavior.propTypes = {
    href: PropTypes.oneOfType([
        PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
        PropTypes.string,
    ]).isRequired,
};

const defaultTheme = createTheme()
const theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
                underline: "hover"
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
    },
    palette: {
        primary: {
            main: '#e8441f',
            contrastText: '#eeeeee'
        },
        secondary: {
            main: '#e7e7e7',
            contrastText: '#777'
        },
        tertiary: defaultTheme.palette.augmentColor({
            color: {main: "#288d31"},
            name: "tertiary"
        }),
        grey: defaultTheme.palette.augmentColor({
            color: {main: "#e1e1e1"},
            name: "grey"
        }),
        formok: defaultTheme.palette.augmentColor({
            color: {main: "#59a871"},
            name: "formok"
        }),
        formedit: defaultTheme.palette.augmentColor({
            color: {main: "#e7dc61"},
            name: "formedit"
        }),
        laurenscantorij: defaultTheme.palette.augmentColor({
            color: {main: "#ef7132"},
            name: "laurenscantorij"
        }),
        choir1: defaultTheme.palette.augmentColor({
            color: {main: "#ef7132"},
            name: "choir1"
        }),
        laurenscollegium: defaultTheme.palette.augmentColor({
            color: {main: "#69b7e8"},
            name: "laurenscollegium"
        }),
        choir2: defaultTheme.palette.augmentColor({
            color: {main: "#69b7e8"},
            name: "choir2"
        }),
        laurenssymfonisch: defaultTheme.palette.augmentColor({
            color: {main: "#4ca832"},
            name: "laurenssymfonisch"
        }),
        choir3: defaultTheme.palette.augmentColor({
            color: {main: "#4ca832"},
            name: "choir3"
        }),

    },
});


const DecorationRoot = () => {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'nl'}>
                    <SnackbarProvider
                        maxSnack={2}
                        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                    >
                        <AppProvider>
                            <Root/>
                        </AppProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>

    )
}

export default DecorationRoot