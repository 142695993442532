import {DateTime} from "luxon";

export const PORT_SERVER = '4010'
export const BASE_URL_SERVER = 'https://graphql.laurensvocaal.nl'//'http://localhost:'+PORT_SERVER
export const BASE_URL_CLIENT = 'https://nieuw.laurensvocaal.nl'//'http://localhost:3000'

export const choirColors = {
    1: "#ef7132",
    2: "#69b7e8",
    3: "#4ca832"
}

export const getChoirColor = (choirs = []) => {
    return choirs[0]?.id ? choirColors[choirs[0].id] : "black"
}

export const COMPONENTS = [
    {
        title: "centerText",
        label: "Gecentreerde tekst/link",
        options: [
            {
                title: "text",
                label: "Tekst",
                type: "text",
                default: ""
            },
            {
                title: "textProps",
                label: "Opmaak",
                type: "radio",
                options: [
                    {label: "platte tekst", value: 'flatText'},
                    {label: "header1", value: 'header1'},
                    {label: "header2", value: 'header2'},
                ],
                valueToJson: {
                    flatText: {align: "center"},
                    header1: {variant: "h4", sx: {color: "#e8441f"}, align: "center"},
                    header2: {variant: "h6", sx: {color: "#e8441f"}, align: "center"}
                },
                default: "{}",
                parse: 'json'
            },
            {
                title: "url",
                label: "Linkadres (optioneel)",
                type: "text",
                default: ""
            },
        ]
    },
    {
        title: "centerImage",
        label: "Gecentreerde foto",
        options: [
            {
                title: "files",
                label: "Bestand",
                type: "files",
                maxFiles: 1,
                default: ""
            },
            {
                title: "imageProps",
                label: "type",
                type: "radio",
                options: [
                    {label: "beeldbreed", value: 'fluid'},
                    {label: "gecentreerd", value: 'centered'},
                ],
                default: "{}",
                parse: 'json'
            },
            {
                title: "width",
                label: "Breedte (optioneel, alleen bij gecentreerd)",
                type: "text",
                default: ""
            },
            {
                title: "url",
                label: "linkadres (optioneel)",
                type: "text",
                default: ""
            },
        ]
    },
    {
        title: "photoText",
        label: "Foto('s) met tekst",
        options: [
            {
                title: "files",
                label: "Bestand",
                type: "files",
                maxFiles: 3,
                default: ""
            },
            {
                title: "placement",
                label: "Zijdigheid tekst",
                type: "radio",
                options: [
                    {label: "links", value: 'left'},
                    {label: "rechts", value: 'right'},
                ],
                default: "{}"
            },
            {
                title: "title",
                label: "Titel",
                type: "text",
                default: ""
            },
            {
                title: "text",
                label: "Tekst",
                type: "tinymce",
                default: ""
            }
        ]
    },
    {
        title: "listen",
        label: "Luistervoorbeelden",
        options: [
            {
                title: "title",
                label: "Titel (optioneel)",
                type: "text",
                default: ""
            },
            {
                title: "media1",
                label: "Youtube Id 1",
                type: "text",
                default: ""
            },
            {
                title: "media2",
                label: "Youtube Id 2",
                type: "text",
                default: ""
            },
            {
                title: "media3",
                label: "Youtube Id 3",
                type: "text",
                default: ""
            }
        ]
    },
    {
        title: "listenText",
        label: "Luistervoorbeeld met tekst",
        options: [
            {
                title: "media1",
                label: "Youtube Id 1",
                type: "text",
                default: ""
            },
            {
                title: "media2",
                label: "Youtube Id 2",
                type: "text",
                default: ""
            },
            {
                title: "media3",
                label: "Youtube Id 3",
                type: "text",
                default: ""
            },
            {
                title: "media4",
                label: "Youtube Id 4",
                type: "text",
                default: ""
            },
            {
                title: "placement",
                label: "Zijdigheid tekst",
                type: "radio",
                options: [
                    {label: "links", value: 'left'},
                    {label: "rechts", value: 'right'},
                ],
                default: "{}"
            },
            {
                title: "title",
                label: "Titel",
                type: "text",
                default: ""
            },
            {
                title: "text",
                label: "Tekst",
                type: "tinymce",
                default: ""
            }
        ]
    },
    {
        title: "threeAppointment",
        label: "Agendablok (max 3 items)",
        options: []
    },
    {
        title: "threeNewsitem",
        label: "Nieuwsblok (max 3 items)",
        options: []
    },

    {
        title: "spacer",
        label: "Afstandhouder",
        options: [
            {
                title: "size",
                label: "Hoogte",
                type: "radio",
                options: [
                    {label: "80 hoog", value: 'large'},
                    {label: "40 hoog", value: 'small'}
                ]
            }
        ]
    },
]
export const componentPropsNew = (type) => {
    return COMPONENTS.find(t => t.title === type)
}

export const getLogo = (id, title = false, small = true) => {
    const path = `/logo/${small ? 'small' : 'large'}/`
    if (title) {
        switch (id) {
            case 1:
                return path + 'LOGO_LC_INNER_TEXT.png'
            case 2:
                return path + 'LOGO_LCR_INNER_TEXT.png'
            case 3:
                return path + 'LOGO_LS_INNER_TEXT.png'
            default:
                return path + 'LOGO_LV_INNER_TEXT.png'
        }
    } else {
        switch (id) {
            case 1:
                return path + 'LOGO_LC_NO_TEXT.png'
            case 2:
                return path + 'LOGO_LCR_NO_TEXT.png'
            case 3:
                return path + 'LOGO_LS_NO_TEXT.png'
            default:
                return path + 'LOGO_LV_NO_TEXT.png'
        }
    }
}


export const banks = [
    {
        title: "Rabobank",
        id: "ideal/RABONL2U"
    },
    {
        title: "ING",
        id: "ideal/INGBNL2A"
    },
    {
        title: "ABN AMRO",
        id: "ideal/ABNANL2A"
    },
    {
        title: "SNS Bank",
        id: "ideal/SNSBNL2A"
    },
    {
        title: "RegioBank",
        id: "ideal/RBRBNL21"
    },
    {
        title: "ASN Bank",
        id: "ideal/ASNBNL21"
    },
    {
        title: "Knab",
        id: "ideal/KNABNL2H"
    },
    {
        title: "Triodos Bank",
        id: "ideal/TRIONL2U"
    },
    {
        title: "van Lanschot",
        id: "ideal/FVLBNL22"
    },
    {
        title: "Bunq",
        id: "ideal/BUNQNL2A"
    }
]


export const Classes = {
    dropzone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    }
}


export const AppointmentPresets = [
    {
        title: "Blanco",
        values: {
            title: '',
            start: DateTime.local(),
            end: DateTime.local().plus({hour: 1}),
            showFrom: DateTime.local(),
            showTo: DateTime.local().plus({month: 3}),
            description: '',
            location: '',
            access: true,
            profiles: [],
            choirs: [],
            entrancetype: 'free'
        }
    },
    {
        title: "Ochtenddienst A",
        values: {
            title: 'Ochtenddienst groep A',
            start: DateTime.fromObject({weekday: 7, hour: 10, minute: 30, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}).plus({hour: 1}),
            showFrom: null,
            showTo: null,
            description: 'Ochtenddienst groep A',
            location: 'Laurenskerk Rotterdam',
            access: false,
            profiles: [3],
            choirs: [1],
            entrancetype: 'free'
        }
    },
    {
        title: "Ochtenddienst B",
        values: {
            title: 'Ochtenddienst groep B',
            start: DateTime.fromObject({weekday: 7, hour: 10, minute: 30, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}),
            showFrom: null,
            showTo: null,
            description: 'Ochtenddienst groep B',
            location: 'Laurenskerk Rotterdam',
            access: false,
            profiles: [3],
            choirs: [1],
            entrancetype: 'free'
        }
    },
    {
        title: "Cantatedienst",
        values: {
            title: 'Cantatedienst',
            start: DateTime.fromObject({weekday: 7, hour: 19, minute: 0, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 20, minute: 15, second: 0, millisecond: 0}),
            showFrom: null,
            showTo: null,
            description: 'Cantatedienst',
            location: 'Laurenskerk Rotterdam',
            access: true,
            profiles: [],
            choirs: [1],
            entrancetype: 'collecte'
        }
    }
]

export const ENTRANCETYPE_OPTIONS = [
    {id: 'free', title: 'toegang vrij'},
    {id: 'collecte', title: 'toegang vrij, collecte bij de uitgang'},
    {id: 'own', title: 'in eigen beheer'},
    {id: 'external', title: 'externe kaartverkoop, zie toelichting'}
]

