import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./templates/pages/ErrorPage";
import DecorationRoot from "./DecorationRoot";

const router = createBrowserRouter([
    {
        path: "/",
        element: <DecorationRoot/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                lazy: () => import("./templates/pages/PageView")
            },
            {
                path: "pages/:title",
                lazy: () => import("./templates/pages/PageView")
            },
            {
                path: "news",
                lazy: () => import("./templates/news/NewsIndex")
            },
            {
                path: "shop",
                children: [
                    {
                        index: true,
                        lazy: () => import("./templates/shop/ShopView")
                    },
                    {
                        path: "checkout",
                        lazy: () => import("./templates/shop/ShopCheckout")
                    }
                ]

            },
            {
                path: "agenda",
                children: [
                    {
                        index: true,
                        lazy: () => import("./templates/appointments/AppointmentIndex")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./templates/appointments/AppointmentView")
                    }
                ]

            },
            {
                path: "orders/:id",
                lazy: () => import("./templates/orders/OrderView")
            },
            {
                path: "invoices/:id",
                lazy: () => import("./templates/orders/OrderInvoiceView")
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
