import {
    AppBar,
    Box,
    Button,
    Container,
    CssBaseline,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Outlet, useHref, useNavigation} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import UserMenu from "./templates/layout/UserMenu";
import LanguageMenu from "./templates/layout/LanguageMenu";
import ShoppingcartMenu from "./templates/layout/ShoppingcartMenu";
import Loading from "./baseElements/Loading";
import {useState} from "react";


const menuLinks = [
    {title: "HOME", link: '/'},
    {title: "AGENDA", link: '/agenda'},
    {title: "OVER ONS", link: '/pages/overons'},
    {title: "HELP MEE", link: '/pages/helpmee'},
    {title: "KIJK & LUISTER", link: '/pages/kijkluister'},
    {title: "SHOP", link: '/shop'},
]

function Root() {

    const navigation = useNavigation();

    const [drawerOpen, setDrawerOpen] = useState(false);


    return (
        <>
            <CssBaseline/>
            <AppBar position="absolute" color={"secondary"}>
                <Container maxWidth={"md"} sx={{paddingLeft: "0px !important", paddingRight: "0px !important"}}>
                    <Toolbar>
                        <Link href={'/'}><img style={{display: 'flex', marginRight: 10}}
                                              src={"/images/icons/favicon-32x32.png"} alt={'logo - home'}/></Link>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {menuLinks.map((link) => (
                                <Button
                                    key={link.title}
                                    //onClick={handleCloseNavMenu}
                                    sx={{my: 2, display: 'block'}}
                                    href={link.link}
                                >
                                    {link.title}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{flexGrow: 1}}/>
                        <ShoppingcartMenu/>
                        <LanguageMenu/>
                        <UserMenu/>
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="show more"
                                // aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={() => {
                                    setDrawerOpen(true)
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
                <Loading loading={navigation.state === "loading"}/>
            </AppBar>
            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false)
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 240},
                }}
            >
                <List>
                    {menuLinks.map((link) => (
                        <ListItem disablePadding key={link.title}>
                            <ListItemButton
                                onClick={() => {
                                    setDrawerOpen(false)
                                }}
                                href={link.link}
                            >
                                <ListItemText primary={link.title}/>
                            </ListItemButton>
                        </ListItem>
                    ))}


                </List>

            </Drawer>
                <Box component="main" sx={{
                    pt: 3,
                    pb: 6,
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <Outlet/>
                <Container maxWidth={"md"}
                           sx={{borderWidth: "1px 0", borderColor: "#777", borderStyle: "solid", mt: 5}}>
                    <Stack direction={"row"} justifyContent="space-evenly" alignItems="center" spacing={2}>
                        <Button
                            sx={{my: 1, display: 'block'}}
                            href={"/"}
                        >
                            home
                        </Button>
                        <Button
                            sx={{my: 1, display: 'block'}}
                            href={"/news"}
                        >
                            nieuws
                        </Button>
                        <Button
                            sx={{my: 1, display: 'block'}}
                            href={"/contact"}
                        >
                            contact
                        </Button>
                    </Stack>
                </Container>
                <Container maxWidth={"md"} sx={{mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid xs={4}>
                            <Typography variant={"h6"} color={"primary"}>contact</Typography>
                            <Typography>Laurens Vocaal</Typography>
                            <Typography>Postbus 21264</Typography>
                            <Typography>3001AG Rotterdam</Typography>
                            <Typography>06 410 79 452</Typography>
                            <Typography>info @ laurensvocaal.nl</Typography>
                            <Typography>(spaties verwijderen)</Typography>
                            <Typography>IBAN NL93 ABNA 0640 7556 66</Typography>
                        </Grid>
                        <Grid xs={4} alignItems={"center"}>
                            <Stack alignItems={"center"} justifyContent={"center"}>
                                <Typography variant={"h6"} color={"primary"}>volg ons</Typography>
                                <div className="g-ytsubscribe" data-channelid="UC1geRuQPeDOPfiZFsb1z3eQ"
                                     data-layout="full" data-count="default"></div>
                                <Link href={'/'}>TWITTER</Link>
                                <Link href={'/'}>FACEBOOK</Link>

                            </Stack>
                        </Grid>
                        <Grid xs={4}>
                            <Stack alignItems={"end"} justifyContent={"center"}>
                                <Typography variant={"h6"} color={"primary"}>snel naar</Typography>
                                <Link href={'/'}>nieuws</Link>
                                <Link href={'/'}>agenda</Link>
                                <Link href={'/'}>over ons</Link>
                                <Link href={'/'}>contact</Link>
                                <Link href={'/'}>help mee</Link>
                                <Link href={'/'}>kijk & luister</Link>
                                <Link href={'/'}>shop</Link>
                                <Link href={'/'}>nieuwsbrief</Link>

                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Root;


const RequireAuth = ({children, redirectTo}) => {
    const href = useHref();
    // const userContext = useContext(AuthContext);
    // return userContext.userLoggedIn() ? children : <Navigate to={redirectTo + (href ? `?redirect=${encodeURIComponent(href)}` : '') }/>
}
RequireAuth.defaultProps = {
    redirectTo: "/login"
}