import {Badge, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import {useContext, useState} from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CounterIcon from "@mui/icons-material/PointOfSale";
import {AppContext} from "../../context/AppContext";
import {useNavigate} from "react-router-dom";
import {getShoppingCartIcon} from "../../utilities/funcs";

const ShoppingcartMenu = props => {


    const appContext = useContext(AppContext)
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
            >
                <Badge badgeContent={appContext.cartitems.length} color="error">
                    <ShoppingCartIcon/>
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                sx={{width: "380px"}}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Typography variant={"h6"} sx={{margin: 1, ml: 2}}>Inhoud winkelwagen</Typography>
                <Divider color={"#777"}/>
                {appContext.cartitems.length === 0 &&
                    <MenuItem>
                        <ListItemText
                            primary={"Uw winkelwagen is leeg"}/>
                    </MenuItem>
                }

                {appContext.cartitems.length > 0 && appContext.cartitems.map((cartitem) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                console.log(cartitem)
                                //navigate('/shop/checkout')
                            }}
                            divider
                            key={cartitem.id}>
                            <ListItemIcon>
                                {getShoppingCartIcon(cartitem.subproduct.product.type, {fontSize: "small"})}
                            </ListItemIcon>

                            <ListItemText
                                primary={cartitem.subproduct.product.title + ' - ' + cartitem.subproduct.title}
                                primaryTypographyProps={{noWrap: true}}
                                secondary={`${cartitem.amount} x ${cartitem.subproduct.price.toFixed(2)}`}/>
                        </MenuItem>
                    )
                })
                }

                {appContext.cartitems.length > 0 && <MenuItem onClick={() => {
                    navigate('/shop/checkout')
                }}>
                    <ListItemIcon>
                        <CounterIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Bestelling afronden</ListItemText>
                </MenuItem>
                }


            </Menu>
        </>
    )
}

export default ShoppingcartMenu