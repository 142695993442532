import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import StoreFrontIcon from "@mui/icons-material/Storefront";
import LogoutIcon from "@mui/icons-material/Logout";
import {useState} from "react";

const login = false

const UserMenu = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircle/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {!login && <Box>
                    <Stack sx={{m: 2}}>
                        <Typography variant={"body2"} sx={{ml: 1, mb: 3}}>Voer uw gegevens in om in te
                            loggen</Typography>
                        <TextField id="username" label="gebruikersnaam" variant="outlined"
                                   InputLabelProps={{shrink: true}} sx={{mb: 1}}/>
                        <TextField id="password" label="wachtwoord" variant="outlined" InputLabelProps={{shrink: true}}
                                   sx={{mb: 1}}/>
                        <Button variant={"outlined"}>Login</Button>

                    </Stack>
                </Box>}
                {login && <Box>
                    <MenuItem>
                        <Avatar/> mijn gegevens
                    </MenuItem>
                    <MenuItem>
                        <Avatar><PasswordIcon/></Avatar> wachtwoord wijzigen
                    </MenuItem>
                    <Divider/>
                    <MenuItem>
                        <ListItemIcon>
                            <StoreFrontIcon fontSize="small"/>
                        </ListItemIcon>
                        bestellingen
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small"/>
                        </ListItemIcon>
                        uitloggen
                    </MenuItem>
                </Box>}
            </Menu>
        </>
    )
}

export default UserMenu